import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import { handleLogin, mutationLogin } from "../../services/auth"
import { isBrowser } from "../../helpers"

export default function Login({ data }) {
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)

  function handleSubmit(e) {
    if (e) e.preventDefault()

    if (!isBrowser) return false

    setProcessing(true)

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        mutationLogin({ login, password }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        if (res.data.errors) {
          const { message } = res.data.errors[0]
          if (
            message === "incorrect_password" ||
            message === "invalid_username" ||
            message === "invalid_email"
          ) {
            setError("Incorrect login details")
            setProcessing(false)
          }

          return null
        }

        setError("")
        setProcessing(false)

        const {
          firstName = "",
          lastName = "",
          email = "",
          username,
        } = res.data.data.login.user

        handleLogin({
          login: username,
          firstName,
          lastName,
          email,
          id: res.data.data.login.user.id,
          token: res.data.data.login.authToken,
          refreshToken: res.data.data.login.refreshToken,
        })

        navigate("/account/offers")
      })
      .catch(err => console.log(err))
  }

  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/account/login/",
      }}
      path={"a"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <form onSubmit={handleSubmit}>
        <h3>Login to your account</h3>
        <p>
          Please complete your login details below to view your secure area:
        </p>
        <label htmlFor="email">Login</label>
        <input
          name="login"
          type="text"
          placeholder="Login"
          onChange={e => setLogin(e.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
        />
        {processing && <div className="logging-in">Processing</div>}
        {!!error && <div>{error}</div>}
        <input type="submit" value="Submit" />
      </form>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
